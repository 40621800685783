import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { formatDate } from '../common/util';
import { useForm } from "react-hook-form";
import moment from 'moment';
import ErrInvoiceReportIcon from "../../images/ErrInvoiceReport.svg";
import SuccessInvoiceValidate from "../../images/success 24x24.svg"
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  FormHelperText,
  FormControl,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import _ from "lodash";
import ErrorLists from "../RecordSaleNew/errorLists";
// import { receiverEmailId } from "../../components/common/constants";


const CustomerTempSales = (props) => {
  const custNameRef = useRef();
  const pageSize = 10;
  const history = useHistory();
  const [count, setCount] = useState();
  const [message, setMessage] = useState('');
  const [errMessage, setErrorMessage] = useState('');
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const [showInvErr, setShowInvErr] = useState(false);
  const [eInvEWBErrorDetails, setErrorDetails] = useState([]);
  // const [paymentTypeId,setPaymentTypeId] = useState('')
  const [saleDate, setSaleDate] = useState({ startDate: null, endDate: null });
  const token = localStorage.getItem("authToken");

  const { handleSubmit, errors, register } = useForm();

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/reports/tempSales`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.invoices);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const triggerTheValidate = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/products/productinventory/validation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (result) {
        await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/invoices/bulkupload/sync`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setMessage(result?.data?.message);
      setIsSuccess(true)
      loadData();
      setIsLoading(false);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
  }
  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    // e.preventDefault();
    setQuery({
      ...query,
      customerName: custNameRef.current.value,
      startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY') : "",
      endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY') : "",
      page: 1
    });
  };


  const handleDateChange = (value, field) => {
    const clonedDate = _.cloneDeep(saleDate);
    clonedDate[field] = value;
    setSaleDate(clonedDate)
  };

  const handleReset = (e) => {
    window.location.reload();
    // setTypeId('');
    // setPaymentId('');
    // setSaleDate({startDate:null,endDate:null});
    // setQuery({ page: 1, pageSize: pageSize })
  }




  const dateValidation = () => {
    let sDate = saleDate.startDate instanceof Date;
    let eDate = saleDate.endDate instanceof Date;
    if (!sDate && !(saleDate.startDate === null)) {
      var startDateArray = saleDate.startDate && saleDate.startDate.split("/");
      saleDate.startDate = new Date(startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2]);
    }
    if (!eDate && !(saleDate.endDate === null)) {
      var endDateArray = saleDate.endDate && saleDate.endDate.split("/");
      saleDate.endDate = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const start = moment(saleDate.startDate);
    const end = moment(saleDate.endDate);
    const diff = end.diff(start, 'day');
    return diff >= 0 || !saleDate.startDate || !saleDate.endDate;
  }

  const endDateValidation = () => {
    const today = moment(saleDate.startDate);
    let eDate = saleDate.endDate instanceof Date;
    if (!eDate && !(saleDate.endDate === null)) {
      var endDateArray = saleDate.endDate && saleDate.endDate.split("/");
      saleDate.endDate = new Date(endDateArray[1] + '/' + endDateArray[0] + '/' + endDateArray[2]);
    }
    const end = moment(saleDate.endDate);
    const diff = end.diff(today, 'minutes');
    return diff > -2
  }

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                Reports
              </Link>
              <Typography component="span" color="textPrimary">
                Customer Temporary Sales
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <form onSubmit={handleSubmit(handleSearch)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Customer Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={custNameRef}
                    InputProps={{
                      name: "custName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=''
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    error={Boolean(errors.startDate)}
                    value={saleDate.startDate}
                    onChange={(value) => handleDateChange(value, "startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    inputRef={register({
                      validate: (saleDate.startDate && saleDate.endDate) ? () => dateValidation() || 'Start date should be lesser than end date' : ""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
                  {errors.startDate && errors.startDate.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    error={Boolean(errors.endDate)}
                    onChange={(value) => handleDateChange(value, "endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                    inputRef={register({
                      validate: (saleDate.startDate && saleDate.endDate) ? () => endDateValidation() || 'End date cannot be lesser than start date' : ""
                    })}
                  />
                </MuiPickersUtilsProvider>
                <FormHelperText error>
                  {errors.endDate && errors.endDate.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid item container justifyContent={"flex-end"} md={12} xs={12}>
              <Box mt={2} mr={2}>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Generate Report
                </Button>
              </Box>
              <Box mt={2} mr={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={triggerTheValidate}
                  startIcon={<CheckIcon />}
                >
                  Inventory availablity check
                </Button></Box>
              <Box mt={2}>
                <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                  clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>

        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Invoice No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Tally Invoice No.</StyledHeaderCell>
                <StyledHeaderCell align="left">Customer Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Invoice Date</StyledHeaderCell>
                <StyledHeaderCell align="right">Invoice Total</StyledHeaderCell>
                <StyledHeaderCell align="left">Sync Status</StyledHeaderCell>
                <StyledHeaderCell align="left">Validation</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => (
                    <TableRow
                      style={{ cursor: props.modal ? "pointer" : "" }}
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }
                    >
                      <TableCell component="th" scope="row">
                        <Link >{row.tempInvoiceNum}</Link>
                      </TableCell>
                      <TableCell align="left">
                        {row.tallyInvoiceNum}
                      </TableCell>
                      <TableCell align="left">
                        {row.customer && row.customer.fullName}
                      </TableCell>

                      <TableCell align="left">
                        {row.invoiceDate && formatDate(row.invoiceDate)}
                      </TableCell>
                      <TableCell align="right">
                        {row.total && (Math.round(row.total * 100) / 100).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {row?.isSync ? "Synced" : "Not Synced"}
                      </TableCell>
                      <TableCell align="center">
                        {
                          (row.isInventoryValidate) ? row?.invErrorList?.length ? <IconButton
                            onClick={() => {
                              setShowInvErr(true);
                              setErrorDetails(row?.invErrorList)
                            }}
                          >
                            <img src={ErrInvoiceReportIcon} alt="history" />
                          </IconButton> : <IconButton
                            onClick={() => {
                              setShowInvErr(true);
                              setErrorDetails(row?.invErrorList)
                            }}
                          >
                            <img src={SuccessInvoiceValidate} alt="history" />
                          </IconButton> : "Inventroy not Validated"}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showInvErr}
        onClose={() => {
          setShowInvErr(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Error lists
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowInvErr(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <ErrorLists setIsError={setIsError} erroInfo={eInvEWBErrorDetails} />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => {
          setIsError(false);
          setErrorMessage("")
        }}
      >
        <MuiAlert
          onClose={() => {
            setIsError(false);
            setErrorMessage("")
          }}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {errMessage ? errMessage : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
          setMessage("")
        }}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
            setMessage("")
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerTempSales;
