import React, { useState } from "react";
import logo from "../../images/fsmLogo.svg";
import axios from "axios";
import { Grid, Button, Box, CircularProgress, Snackbar, Typography, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import _ from "lodash";

const companyCurrency = "INR";

let loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

let generateReceiptNumber = (customerId) => {
  // Get current date and time
  const now = new Date();

  // Format date and time components
  const year = now.getFullYear().toString().substring(-2); // Get last two digits of the year
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Month starts from 0, so add 1
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  // Combine date and time components
  const dateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;

  // Generate invoice number
  const invoiceNumber = `REC-${customerId}-${dateTime}`;

  return invoiceNumber;
}

let displayRazorpay = async (event, data, successCallBack, errorCallBack) => {
  const res = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const token = localStorage.getItem("authToken");

  // Creating receipt number

  let receiptNumber = await generateReceiptNumber(data.customerId)
  // creating a new order
  const result = await axios.post(
    `${process.env.REACT_APP_SERVICE_URL}/rarwaypgw/order`,
    {
      "amount": (data.amountPaid) * 100,
      "currency": companyCurrency,
      "receipt": `${receiptNumber}`
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  // Getting the order details back
  const { amount, id: order_id, currency } = result.data;

  const options = {
    key: "rzp_test_WPmhnkpUhJOnjq", // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "ATC",
    description: "Test Transaction",
    image: { logo },
    order_id: order_id,
    handler: async function (response) {
      if (response) {
        response.order_id = order_id;
        response.receiptNumber = receiptNumber;
        successCallBack(response);
      } else {
        alert("Something went wrong please try again.");
        errorCallBack();
      }


    },
    prefill: {
      name: data.customerData && data.customerData.tradeName,
      email: data.customerData && data.customerData.email,
      contact: data.customerData && data.customerData.mobileNumber,
    },
    notes: {
      address: "",
    },
    theme: {
      color: "#61dafb",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

const RazorpayPGW = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isWarning, setWarning] = useState(false);

  const tokenT = localStorage.getItem("authToken");
  // const [invDetails, setInvoiceDetails] = React.useState(null);

  // useEffect(() => {
  //   if(props.invoiceId){
  //     getCustomerInvoice(props.invoiceId,tokenT, props.setMessage, props.setIsError );
  //   }
  // }, [props.invoiceId, tokenT, props.setMessage, props.setIsError]); 


  // const getCustomerInvoice = async (invoiceNo, authToken, setMessage, setIsError) => {
  //   try {
  //     const result = await axios.get(
  //       `${process.env.REACT_APP_SERVICE_URL}/invoices/${invoiceNo}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //     const sales = result.data && result.data;
  //     setInvoiceDetails(sales);
  //   }
  //   catch (err) {
  //     setMessage("Unble to get the invoice details.")
  //     setIsError(true)
  //   }
  // }




  const paymentData = props.calculate ? _.cloneDeep(props.calculate) : {};
  let payments = {
    status: "",
    paymentModeId: "",
    paymentId: "",
    amount: (props.isAdavancePayment) ? Math.round(props.amountPaid) : Math.round(paymentData.total),
    totalAmount: (props.isAdavancePayment) ? Math.round(props.amountPaid) : Math.round(paymentData.total),
    amountPaid: Math.round(props.amountPaid),
    paymentDetails: "",
    customerId: props.customerId,
    customerData: props.customerData
  }

  let createPaymentDetails = async (response) => {
    if (props.calculate || props.isAdavancePayment) {
      setIsLoading(true)
      // const paymentData = props.calculate ? _.cloneDeep(props.calculate) : {}
      try {
        const razorpayPayment = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/rarwaypgw/payment`,
          {
            razorpayPaymentId: response.razorpay_payment_id,
            amount: Math.round(props.amountPaid) * 100,
            currency: companyCurrency
          },
          {
            headers: {
              Authorization: `Bearer ${tokenT}`,
            },
          }
        );
        const razorpayStatus = razorpayPayment && razorpayPayment.data && razorpayPayment.data.status;
        if (razorpayStatus === "SUCCESS") {
          props.setIsSuccess && props.setIsSuccess(response);
          if(props.isAdavancePayment){setIsLoading(false)}
        } else {
          props.setIsSuccess && props.setIsSuccess(response)
          props.setMessage && props.setMessage("Payment Done, Somethig went wrong in payment entry");
          props.setIsError && props.setIsError(true)
          props.setMessage && props.setMessage("Payment Done, But unble create the payment entry in Razorpay");
          setIsLoading(false)
        }
        // if (razorpayStatus === "SUCCESS") {
        //   let payments =
        // {
        //   invoiceId: Number(props.invoiceId),
        //   status: "PAID",
        //   paymentMethodId: props.paymentMethodId,
        //   paymentModeId: 1,
        //   paymentId: "",
        //   amount: Math.round(paymentData.total),
        //   totalAmount: Math.round(paymentData.total),
        //   paidAmount: Math.round(paymentData.total),
        //   paymentDetails: "",
        //   razorPayOrderCreationId: response.order_id,
        //   razorpayPaymentId: response.razorpay_payment_id,
        //   razorpayOrderId: response.razorpay_order_id,
        //   razorpaySignature: response.razorpay_signature,
        //   erpInvoiceNum: props.erpInvoiceId,
        //   customerId: props.customerId
        // }
        // //Post or put the req to save changes to db
        // const result = await axios.post(
        //   `${process.env.REACT_APP_SERVICE_URL}/payment`,
        //   payments,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${tokenT}`,
        //     },
        //   }
        // );
        // const resStatus = result && result.data && result.data.status;

        // if (resStatus === "SUCCESS") {
        //   if(invDetails && invDetails.isEInvoice && invDetails.isBtoB){
        //     let invNum = props.invoiceId;
        //     let createEInvQR = await axios.post(
        //       `${process.env.REACT_APP_SERVICE_URL}/qrcode/${invNum}`,{invNum},
        //       {
        //         headers: {
        //           Authorization: `Bearer ${tokenT}`
        //         },
        //       }
        //     );
        //     if(createEInvQR){
        //       props.setMessage(result && result.data && result.data.message);
        //       setIsLoading(false);
        //       props.setInvoiceNo(props.invoiceId)
        //       props.setIsSuccess(true);
        //       props.setIsSaved(true);
        //       props.cancel();
        //     }else{
        //       props.setMessage(result && result.data && result.data.message,"Unable to generate eInvoice");
        //       setIsLoading(false);
        //       props.setInvoiceNo(props.invoiceId)
        //       props.setIsSuccess(true);
        //       props.setIsSaved(true);
        //       props.cancel();
        //     }
        //   }else{
        //     props.setMessage(result && result.data && result.data.message);
        //     setIsLoading(false);
        //     props.setInvoiceNo(props.invoiceId)
        //     props.setIsSuccess(true);
        //     props.setIsSaved(true);
        //     props.cancel();
        //   }
        // } else {
        //   props.setMessage("Payment Done, But unble create the payment entry")
        //   setIsLoading(false)
        //   props.setMessage(result && result.data && result.data.message)
        //   props.setIsError(true)
        //   props.cancel()
        // }
        // } else {
        //   props.setMessage("Payment Done, But unble create the payment entry in Razorpay")
        // }
      }
      catch (err) {
        props.setIsSuccess && props.setIsSuccess(response)
        props.setMessage && props.setMessage("Payment Done, Somethig went wrong in payment entry");
        console.log("err", err);
        setIsLoading(false)
        props.setIsError && props.setIsError(true)
        // props.cancel()
      }
    } else {
      props.setMessage && props.setMessage("Send all required details");
      setIsLoading(false)
      props.setIsError && props.setIsError(true)
      // props.cancel()
    }
  }
  let errorDetails = () => {
    props.cancel();
  }
  let showError = () => {
    setWarning(true)
  }
  return (
    <Grid container>
      {/* <Grid item xs={4}>
      </Grid> */}
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <img src={logo} className="App-logo" alt="logo" style={{ height: "70px", width: "70px" }} />
            <h4>Pay now!</h4>
          </Grid> */}
          <Grid item xs={6}>
            <Typography>Total Amount</Typography>
            <TextField
              variant="outlined"
              fullWidth
              size="small" value={(props.isAdavancePayment) ? Math.round(props.amountPaid) : props.calculate && props.calculate.total ? (Math.round(props.calculate.total * 100) / 100).toFixed(2) : 0}
              name="amount" disabled
            />

          </Grid>
          <Grid item xs={6}>
            <Typography>Amount Paid </Typography>
            <TextField inputProps={{
              style: {
                color: '#008000'
              }
            }}
              variant="outlined"
              fullWidth
              size="small"
              value={props.amountPaid}
              name="amountPaid" disabled={((props.paymentType && props.paymentType.id && props.paymentType.id !== 2) || props.disableAmount) ? true : false}
              onChange={(e) => {
                let amountT = props.calculate && props.calculate.total ? (Math.round(props.calculate.total * 100) / 100).toFixed(2) : 0;
                const amountE = e.target.value;
                if (amountE >= Number(amountT)) {
                  props.setAmountPaid(amountT - 1);
                } else {
                  props.setAmountPaid(amountE)
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={4} alignContent="center">
              <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
              <Box>
                <Button onClick={(props.amountPaid > 0) ? (e) => { displayRazorpay(e, payments, createPaymentDetails, errorDetails) } : showError}
                  color="primary" size="small"
                  variant="contained" >
                  Pay ₹{(props.amountPaid) ? props.amountPaid : "0.0"}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Snackbar
          open={isWarning}
          autoHideDuration={2000}
          onClose={() => setWarning(false)}
        >
          <MuiAlert
            onClose={() => setWarning(false)}
            elevation={6}
            variant="filled"
            severity="warning"
          >
            Paid Amount Greater than 0
          </MuiAlert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default RazorpayPGW;
